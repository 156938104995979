import React from 'react';
import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom'; 

import Notification from "./Components/firebaseNotificationComponent/Notification.js"
import CardCourses from './Components/Courses/CardCourses/CardCourses.js';
import CourseCancilBooking from './Components/Courses/CourseCancilBooking/CourseCancilBooking.js';
import CourseOutline from './Components/Courses/CourseOutline/CourseOutline.js';

//booking expert started
const ExpertUpcomeingAndPastSessions = React.lazy(() => import('./Components/ExpertScreens/ExpertUpcomeingAndPastSessions/ExpertUpcomeingAndPastSessions.js'));
const ExpertSessionsProfile = React.lazy(() => import('./Components/ExpertScreens/ExpertSessionsProfile/ExpertSessionsProfile.js'));
const ExpertSessionsSettings = React.lazy(() => import('./Components/ExpertScreens/ExpertSessionsSettings/ExpertSessionsSettings.js'));
//booking expert ended

//become expert started
const ExpertProfile = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertProfile/ExpertProfile.js'));
const ExpertKnlodge = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertKnlodge/ExpertKnlodge.js'));
const ExpertIdentity = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertIdentity/ExpertIdentity.js'));
const ExpertMethod = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertMethod/ExpertMethod.js'));
const ExpertVerify = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertVerify/ExpertVerify.js'));
//become expert ended

// signin screen started
const RecessSignIn = React.lazy(() => import("./Components/Authentication/RecessHeader/RecessSignIn"));
const SettingDetails = React.lazy(() => import('./Components/SettingsScreen/SettingDetails/SettingDetails.js'));
const SettingFavourite = React.lazy(() => import('./Components/SettingsScreen/SettingFavourite/SettingFavourite.js'));
const SettingLearningSession = React.lazy(() => import('./Components/SettingsScreen/SettingLearningSession/SettingLearningSession.js'));
const SettingNotification = React.lazy(() => import('./Components/SettingsScreen/SettingNotification/SettingNotification.js'));
const SettingSignInScreen = React.lazy(() => import('./Components/SettingsScreen/SettingSignInScreen/SettingSignInScreen.js'));
const SettingPrivacy = React.lazy(() => import('./Components/SettingsScreen/SettingPrivacy/SettingPrivacy.js'));
const SettingWallet = React.lazy(() => import('./Components/SettingsScreen/SettingWallet/SettingWallet.js'));
const SettingChangePassword = React.lazy(() => import('./Components/SettingsScreen/SettingChangePassword/SettingChangePassword.js'));
const SettingNameScreen = React.lazy(() => import('./Components/SettingsScreen/SettingNameScreen/SettingNameScreen.js'));
// signin screen ended

//  expert screens started
const ExpertSessions = React.lazy(() => import("./Components/ExpertScreens/ExpertSessions/ExpertSessions.js"));
const BecomeExpertNavBar = React.lazy(() => import('./RecessNavbar/BecomeExpertNavBar/BecomeExpertNavBar.js'))
const BecomeExpertMainScreen = React.lazy(() => import('./Components/BecomeExpertScreens/BecomeExpertMainScreen/BecomeExpertMainScreen.js'))
const BecomeExpert = React.lazy(() => import('./Components/BecomeExpertScreens/BecomeExpert/BecomeExpert.js'));
const ExpertCongras = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertCongras/ExpertCongras.js'))
const ExpertNotApprove = React.lazy(() => import('./Components/BecomeExpertScreens/ExpertNotApprove/ExpertNotApprove.js'))
//  expert screen ended

// setting screens started
const SettingsMainScreen = React.lazy(() => import("./Components/SettingsScreen/SettingsMainScreen/SettingsMainScreen.js"));
const OtherUserProfile = React.lazy(() => import("./Pages/OtherUserProfile/OtherUserProfile.js"));
const PreSession = React.lazy(() => import("./Pages/OtherUserProfile/Pre-Session/PreSession.js"));
const BookSession = React.lazy(() => import("./Pages/OtherUserProfile/BookSession/BookSession.js"));
const ConfirmBooking = React.lazy(() => import("./Pages/OtherUserProfile/ConfirmBooking/ConfirmBooking.js"));
const DoneBooking = React.lazy(() => import("./Pages/OtherUserProfile/BookingConfirmed/DoneBooking.js"));
const ExpertNotification = React.lazy(() => import("./Pages/OtherUserProfile/ExpertNotification/ExpertNotification.js"));

const Subject_bookmark = React.lazy(() => import("./Pages/OtherUserProfile/Subject_bookmark/subject_bookmark.js"));
// setting screen ended

//video screen 
const VideoAudioScreen = React.lazy(()=>import("./Components/AgoraVideoComponent/Video.js"));
const AudioScreen = React.lazy(()=>import("./Components/AgoraAudioComponent/Audio.js"));
const ChatScreen = React.lazy(()=>import("./Components/AgoraChatComponent/Chat.js"));
function App() {
  return (
    <div className="App" >
      <React.Fragment>
        <HashRouter>
        <React.Suspense fallback={
                <div className='d-flex justify-content-center mt-4'>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only"></span>
                  </div>
                  <h4 className='text-danger me-4 text-bold'>&nbsp;&nbsp;Loading.....</h4>
                </div>
              }>
            <Notification/>
            <Routes>
              <Route path='/' index element={<RecessSignIn />} />

              <Route path='/BecomeExpertMainScreen'  element={<BecomeExpertMainScreen />} >
                <Route path='BecomeExpert'  element={<BecomeExpert />} />
                <Route path='ExpertProfile' element={<ExpertProfile/>} />
                <Route path='ExpertKnlodge' element={<ExpertKnlodge/>} />
                <Route path='ExpertIdentity' element={<ExpertIdentity/>}/>
                <Route path='ExpertMethod' element={<ExpertMethod/>} />
                <Route path='ExpertVerify' element={<ExpertVerify/>} />
              </Route>

              <Route path='/ExpertCongras' element={<ExpertCongras/>}/>
              <Route path='/VideoScreen' element={<VideoAudioScreen/>}/>
              <Route path='/AudioScreen' element={<AudioScreen/>}/>
              <Route path='/ChatScreen' element={<ChatScreen/>}/>
              <Route path='/ExpertNotApprove' element={<ExpertNotApprove/>}/>

              <Route path='/ExpertSessions' element={<ExpertSessions/>} >
              <Route path='ExpertUpcomeingAndPastSessions' element={<ExpertUpcomeingAndPastSessions/>}/>
              <Route path='ExpertSessionsProfile' element={<ExpertSessionsProfile/>}/>
              <Route path='ExpertSessionsSettings' element={<ExpertSessionsSettings/>} />
              </Route>
              <Route path='/OtherUserProfile' element={<OtherUserProfile/>} />
              <Route path='/PreSession' element={<PreSession/>} />
              <Route path='/BookSession' element={<BookSession/>} />
              <Route path='/ConfirmBooking' element={<ConfirmBooking/>} />
              <Route path='/DoneBooking' element={<DoneBooking/>} />
              <Route path='/ExpertNotification' element={<ExpertNotification/>} />

              <Route path='/SettingsMainScreen'  element={<SettingsMainScreen />}>

              <Route path='SettingSignInScreen' element={<SettingSignInScreen/>}/>
              <Route path='SettingNotification' element={<SettingNotification/>}/>
              <Route path='SettingDetails' element={<SettingDetails/>}/>
              <Route path='SettingLearningSession' element={<SettingLearningSession/>}/>
              <Route path='SettingFavourite' element={<SettingFavourite/>}/>
              <Route path='SettingWallet' element = {<SettingWallet/>}/>
              <Route path="SettingPrivacy" element = {<SettingPrivacy/>}/>
              <Route path="SettingChangePassword" element = {<SettingChangePassword/>}/>
              <Route path="SettingNameScreen" element = {<SettingNameScreen/>}/>
              </Route>

              <Route path='/Subject_bookmark' element={<Subject_bookmark />}/>

              <Route path='/CardCourses' element={<CardCourses/>}/>
              <Route path='/CourseOutline' element={<CourseOutline/>}/>
              <Route path='/CourseCancilBooking' element={<CourseCancilBooking/>}/>
              
            </Routes>
          </React.Suspense>
        </HashRouter>
      </React.Fragment>
    </div>
  );
}

export default App;

