import Footer from "../../../Shade/Footer/Footer";
import Headings from "../../../Shade/Headings/Headings";
import Line from "../../../Shade/Line/Line";
import "./CardCourses.css";
import rightarrow from "../../../Assets/rightarrow.svg";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; 
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_URL } from "../../../server";

const CardCourses = () =>{

    const navigate = useNavigate();
    const [cardDetails,setCardDetails] = useState([]);

    const token = sessionStorage.getItem('token');

    useEffect(() =>{
        getCardDetails();
    },[])
    const getCardDetails = () =>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = "";
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(API_URL + "getAllCourse", requestOptions)
          .then((response) => response.json())
          .then((result) =>{
            if(result.Status == true){
                console.log("getAllCourse",result)
                setCardDetails(result.data);
            }
          })
          .catch((error) => console.error(error));
    }
    const gotocoursesoutline = (subjectname,courseName,subid) =>{
        navigate('/CourseOutline',{
            state :{
                subjectname : subjectname,
                courseName : courseName,
                subid : subid
            }
        })
    }
    return(
        <div style={{height:'100vh'}}>
            <Headings screenHeadingName = 'courses'/>
            <p style={{marginTop:'-20px'}}></p>
            <Line/>
            <p className="cpara" style={{padding:'0px 20px'}}>Enrol in professionally designed courses, learn in-demand skills from industry experts, and earn a verified certificate upon completion.</p>
            <Line />
            
            {/* card details started */}
            <div style={{ padding: "20px",cursor:'pointer'}}>
            <div className="row">
                {
                cardDetails?.map((details, index) => (
                    <div key={index} className="col-12 col-md-4  col-lg-4 mb-4" onClick={()=>gotocoursesoutline(details.mySubject[0],details.courseName,details._id)}>
                    <div className="card h-100 pt-4 pb-3 pe-3 ps-3">
                         {/* First row */}
                         <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <p className="ctitle m-0">{details.courseName}</p>
                            </div>
                            <div style={{ width: '1.5rem', height: '1.5rem' }} className="ms-auto d-flex align-items-center justify-content-center">
                                <CircularProgressbar
                                    maxValue={5}
                                    value={details.val}
                                    styles={buildStyles({
                                        pathColor: '#005230',
                                    })}
                                />
                            </div>
                        </div>
                        {/* second row */}
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="w-75">
                            <p className="cpara w-100 text-left">{details.courseDescription}</p>
                            </div>
                            <div>
                            <p className="cpara">{details.course[0].Answer[0].courseTime} -hour course</p>
                            </div>
                        </div>
                        {/* third row */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <button className="csub d-flex align-items-center">{details.mySubject[0]} <img src={rightarrow} style={{height:'0.8rem',width:'0.8rem'}}/></button>
                            </div>
                            <div>
                             <button className="cstart">Start now</button>
                            </div>
                        </div>
                    </div>
                    </div>
                ))
                }
            </div>
            </div>
            {/* card details ended */}
            <p style={{height:'15vh'}}></p>
            <Line/>
            <Footer/>
        </div>
    );
}
export default CardCourses;