import React, { useState, useEffect } from 'react';
import { getFirebaseToken,onMessageListener } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Notification.css"
const App = () => {
    const [isTokenFound, setTokenFound] = useState(false);
    const [notification, setNotification] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      getFirebaseToken(setTokenFound);
    }, []);
  
    onMessageListener()
      .then((payload) => {
        console.log("payload",payload);
        const { title, body } = payload.notification;
        showCustomNotification(title, body);
      })
      .catch((err) => console.log('Failed to receive message: ', err));

    //   const showCustomNotification = (title, body) => {
    //     toast.dismiss();  // Dismiss existing toasts to avoid duplicates
    // toast(
    //   <div className="custom-notification">
    //     <div className="notification-header">
    //       <img src={require("../../Assets/notification.png")} alt="Netflix Icon" className="notification-icon" />
    //     </div>
    //     <div className='notification-rightconatiner'>
    //     <div className="notification-title">{title}</div>
    //     <div className="notification-body">{body}</div>
    //     </div>
    //   </div>,
    //   {
    //     position: "top-right",
    //     autoClose: 5000,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     icon: false,  // Hide the default icon
    //   }
    // );
    //   };
    
    const showCustomNotification = (title, body) => {
        setNotification({ title, body });
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 5000);
      };
      
  
    return (
        <>
         {isVisible && notification && (
         <div className="custom-notification">
          <div className="notification-header">
            <img src={require("../../Assets/notification.png")} alt="Netflix Icon" className="notification-icon" />
          </div>
          <div className='notification-body-conatiner'>
          <span className="notification-title">{notification.title}</span>
          <span className="notification-body">{notification.body}</span>
          </div>
        </div>
         )}
      </>
    );
  };
  
  export default App;