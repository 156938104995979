import Footer from '../../../Shade/Footer/Footer';
import Headings from '../../../Shade/Headings/Headings';
import Line from '../../../Shade/Line/Line';
import './CourseOutline.css';

import corseright from "../../../Assets/corseright.svg";
import corseclock from "../../../Assets/corseclock.svg";
import corsedesktop from "../../../Assets/corsedesktop.svg";
import leftarrow from "../../../Assets/left_arrow.svg";
import rightarrow from "../../../Assets/rightarrow.svg";
import corsevideo from "../../../Assets/corsevideo.svg";
import corselock from "../../../Assets/corselock.svg";
import cartadded from "../../../Assets/cartadded.svg";
import cartempty from "../../../Assets/cartempty.svg";
import tickmark from "../../../Assets/carttick.svg";

import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { API_URL, IMG_PATH } from '../../../server';
import { useLocation, useNavigate } from 'react-router-dom';

import ReactStars from "react-rating-stars-component";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CourseOutline = () =>{

    const [chooseExpertSession,setChooseExpertSession] = useState(false);
    const [calanderModal,setCalanderModal] = useState(false);
    const [outlinedetails,setOutlineDetails] = useState([]);
    const [courseHighlets, setCourseHighlets] = useState([]);
    const [courseSessions, setCourseSessions] = useState([]);
    const [cardDetails,setCardDetails] = useState([]);
    const [totalExpertData, setTotalExpertData] = useState([]);

    const token = sessionStorage.getItem('token');
    const registerid = sessionStorage.getItem('registerid');
    const navigate = useNavigate();
    const loc = useLocation();
    const {subjectname,courseName,subid} = loc?.state;
    console.log('subjectname',subjectname, 'subid',subid, "courseName", courseName)

    useEffect(() =>{
        getCardDetails();
        chooseExpert();
        const today = dayjs().format('YYYY-MM-DD'); 
        setSelectedDate(today);
    },[])
    // slots related functionality stared
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [disabledDays, setDisabledDays] = useState([]);
    const [selectedDate,setSelectedDate] = useState('');
    const [expertId, setExpertId] = useState('');
    const parsedDate = new Date(selectedDate);
    console.log('Selected date:', days[parsedDate.getDay()]);
    const [daySlots, setDaySlots] = useState([]);
    console.log('dayslots',daySlots);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedInputIndex, setSelectedInputIndex] = useState(null);
    const [subjectExpertId, setsubjectexpertid] = useState('');

    const itemsPerPage = 8; 
    const [storedValues, setStoredValues] = useState({});
    console.log(storedValues)
    const totalPages = Math.ceil(daySlots[0]?.slots.length / itemsPerPage);
    const currentSlots = daySlots[0]?.slots.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const today = dayjs();
    const maxDate = today.add(1, 'month').endOf('month');

    useEffect(() =>{
        getExpertSettingById(subjectExpertId);
        setCurrentPage(0);
        setSelectedInputIndex(null);
    },[selectedDate]);
    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(prev => prev + 1);
            setSelectedInputIndex(null); 
        }
    };
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(prev => prev - 1);
            setSelectedInputIndex(null);
        }
    };
    const handleInputClick = (index, value) => {
        setSelectedInputIndex(index); 
        setStoredValues(value);
    };   
     // slots related functionality ended

    const getCardDetails = () =>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = "";
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(API_URL + "getAllCourse", requestOptions)
          .then((response) => response.json())
          .then((result) =>{
            if(result.Status == true){
                console.log("getAllCourse",result)
                const filteredCourses = 
                result.data.filter(course => course.courseName == courseName);
                console.log('filteredCourses',filteredCourses)
                setOutlineDetails(filteredCourses[0].course);
                setCourseHighlets(filteredCourses[0].courseHighlights)
                setCourseSessions(filteredCourses[0].courseSession)
            }
          })
          .catch((error) => console.error(error));
    }

    const chooseExpert = () =>{
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = JSON.stringify({
          "user_id": registerid,
          "mySubject": subjectname
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(API_URL + "getMySubjectById", requestOptions)
          .then((response) => response.json())
          .then((result) =>{
            if(result.Status === true){
                console.log("getMySubjectById",result);
                setCardDetails(result.data);
            }
          })
          .catch((error) => console.error(error));
    }
    const bookmarkforuser = (expert_id) =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = JSON.stringify({
        "user_id": registerid,
        "expert_id": expert_id
        });
        console.log(raw, 'raw')
        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };
        
        fetch(API_URL + "bookMarkForUser", requestOptions)
        .then((response) => response.json())
        .then((result) =>{
            console.log("bookMarkForUser",result)
            chooseExpert()
        })
        .catch((error) => console.error(error));
    }

    const getExpertSettingById = (exid) =>{
        setsubjectexpertid(exid);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = JSON.stringify({
          "expert_id": exid
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(API_URL + "getExpertSettingById", requestOptions)
          .then((response) => response.json())
          .then((result) =>{
            if(result.Status === true){
                console.log('getExpertSettingById',result)
                setTotalExpertData(result.data);
                const disableddays =  result.data.newTiming.filter(day => 
                day.slots.some(slot => !slot.isBooked)).map(day => day.day);
                setDisabledDays(disableddays)
                const filterdayslots = result.data.newTiming.filter(sloat => 
                    sloat.day === days[parsedDate.getDay()] || sloat.day === days[parsedDate.getDay()].substring(0, 3)
                );
                setDaySlots(filterdayslots);                
            }
          })
          .catch((error) => console.error(error));
    }
    const bookexpert = () =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        
        const raw = JSON.stringify({
          "user_id": registerid,
          "expert_id": expertId,
          "amount": totalExpertData?.pricePerMinute,
          "levelOfSubjectKnowledge": "advanced",
          "preferredSessionType": totalExpertData?.preferredSessionType,
          "selectYourSession": totalExpertData?.selectYourSession,
          "chooseDate": selectedDate,
          "chooseTime": storedValues,
          "message": totalExpertData?.message,
          "day": days[parsedDate.getDay()],
        });
        console.log(raw,'raw')
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(API_URL + "bookExpert", requestOptions)
          .then((response) => response.json())
          .then((result) =>{
           if(result.Status === true){
            console.log("bookExpert",result)
            toast.success(result.message);
           }
           else{
            toast.error(result.message);
           }
          })
          .catch((error) => console.error(error));
    }
    return(
        <div>
            <Headings screenHeadingName = 'courses'/>
            <p style={{marginTop:'-20px'}}></p>
            <Line/>
            <ToastContainer />
            {/* body started */}
            <div className='d-flex gap-5 mt-5' style={{paddingLeft:'20px',paddingRight:'20px'}}>
                <div style={{maxWidth:'450px'}}>
                    <dl>
                        <div>
                         <h1 className='cotitle1'>AI Essentials</h1>
                        </div>
                        <div className='d-flex mt-3'>
                            <lable className='copara me-5 d-flex align-items-center'><img src={corseclock} className='me-1'/>8-hour course</lable>
                            <label className='copara d-flex align-items-center'><img src={corsedesktop} className='me-1'/>Desktop recommended </label>
                        </div>
                        <p className='copara mt-3'>Understand the basics of AI with key information about how it all works,
                             the important tools to leverage,and where the future of the industry is headed.</p>
                        <hr className='coline'/>
                        <div className='d-flex'>
                        {
                            courseSessions?.map((cs,index) =>
                            <div key={index}>
                             <button className='cobtn1 copara me-5'>{cs}</button>
                            </div>
                            )
                        }
                        </div>
                        <dt className='copara mb-2 mt-4' style={{fontFamily:'AvenirNextLTPro-Bold',fontSize:'0.8rem'}}>Course Highlights</dt>
                        {
                        courseHighlets?.map((ch,index) =>
                        <div key={index}>
                         <dd className='copara d-flex align-items-center'><img src={corseright} className='me-2'/>{ch}</dd>
                        </div>
                        )
                        }
                        <hr className='coline'/>
                        <div className='d-flex flex-column'>
                            <button className='cobtn2 d-flex ps-4 align-items-center'>Artificial Intelligence <img src={rightarrow} style={{height:'0.9rem',width:'0.9rem'}} className='ms-auto me-3'/></button>
                            <button
                             onClick={() => navigate('/CardCourses')}   
                             className='cobtn2 d-flex ps-4 align-items-center'><img src={leftarrow} style={{height:'0.9rem',width:'0.9rem'}} className='me-3'/>Courses</button>
                        </div>
                    </dl>
                </div>

                <div style={{maxWidth:'450px'}}>
                    <h1 className='cotitle2'>Course Outline</h1>
                    <hr className='coline'/>
                    <p className='copara mb-4'>You must complete each session to move on to the next.
                     Feel free to book additional sessions with Experts for extra support anytime.</p>
                    <div style={{overflowY:'scroll',height:'500px', scrollbarWidth:'none'}}>
                    {outlinedetails?.map((od, index) => (
                        <div key={index} className='card coline p-3 mb-3' style={{ border: '2px solid #D0D0D0' }}>
                            <div className='d-flex justify-content-between'>
                                <div className='cosubtitle'>{od.Question}</div>
                                <div className='copara d-flex align-items-center' style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem' }}>
                                    <img src={corsevideo} className='me-1' alt="video icon" />
                                    {od.Answer[0]?.courseTime || 'N/A'} min
                                </div>
                            </div>
                            <hr className='coline' />
                            
                            {od.Answer[0]?.answerText.map((ans, idx) => (
                                <span key={idx} className='copara d-flex align-items-center'>
                                    <img src={corseright} className='me-2' alt="right icon" />
                                    {ans}
                                </span>
                            ))}

                            <hr className='coline' />
                            <div className='d-flex justify-content-between align-items-center'>
                                <span onClick={() => setChooseExpertSession(true)} className='copara' style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem', cursor: 'pointer' }}>
                                    Choose Expert
                                </span>
                                <img src={corselock} alt="lock icon" />
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* body ended */}
            <Line/>
            <Footer />

            <Modal centered show={chooseExpertSession} onHide={() => setChooseExpertSession(false)}>
                <Modal.Body style={{backgroundColor:'#F5F3EE',borderRadius:'8px',width:'500px'}}>
                <Modal.Header className="btn btn-close ms-auto" onClick={()=> setChooseExpertSession(false)}/>
                    <div className='p-4'>
                        <p className='cotitle2'>Choose Session Expert</p>
                        <hr className='coline'/>
                        <p className='copara'>If you'd like to learn more about an Expert before deciding,
                            we suggest checking out their profiles and booking an intro session
                            to see if they're the right fit.</p>
                        <hr className='coline'/>
                        <div style={{overflowY:'scroll',height:'300px',scrollbarWidth:'none'}}>
                        {
                            cardDetails?.map((carddetail, index) => (
                                <div key={index}>
                                <div className="card p-3 mb-3">
                                    <div>
                                    {/* First Row: Image, Name, and One-liner */}
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className="d-flex align-items-center gap-3">
                                        <img
                                            src={IMG_PATH + carddetail.image}
                                            alt="Card Avatar"
                                            style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                        />
                                        <div>
                                            <h6 className="cotitle2 mt-2" style={{ fontSize: '1rem' }}>
                                            {carddetail.name}
                                            </h6>
                                            <p className="mb-0 copara">{carddetail.yourOneLiner}</p>
                                            <span className="copara">Speaks: {carddetail.languages.join(', ')}</span>
                                        </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-end">
                                        <div className="d-flex justify-content-end mb-3 gap-0">
                                            {carddetail.isBookedMark ? (
                                            <div onClick={() => bookmarkforuser(carddetail.user_id)} className="d-flex gap-0  align-items-center">
                                                <img src={cartadded} />
                                                <img src={tickmark} />
                                            </div>
                                            ) : (
                                            <div onClick={() => bookmarkforuser(carddetail.user_id)} className="d-flex gap-0 align-items-center">
                                                <img src={cartempty} />
                                                <img src={tickmark} />
                                            </div>
                                            )}
                                        </div>
                                        <span className="cotitle2" style={{ fontSize: '0.9rem' }}>
                                            ${carddetail.pricePerMinute}/min
                                        </span>
                                        </div>
                                    </div>

                                    {/* Second Row: Rating and Subject */}
                                        <div className="d-flex align-items-center gap-2" style={{marginTop:'-10px'}}>
                                            {/* Rating */} 
                                            <div className="me-2 d-flex flex-column">
                                                <span>
                                                <ReactStars
                                                    count={5}
                                                    value={Math.round(carddetail.rating)}
                                                    isHalf={false}
                                                    edit={false}
                                                    activeColor="#D9B65B"
                                                    size={18}
                                                />
                                                </span>
                                                <span
                                                className="copara ms-2"
                                                style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.6rem' }}
                                                >
                                                {carddetail.setYourFeeForPreBookedSession} Sessions
                                                </span>
                                            </div>

                                            {/* Subject Button */}
                                            <div className="ms-1 d-flex flex-column">
                                                <span
                                                className="cocardbtn1 d-flex align-items-center mt-1"
                                                style={{
                                                    width: 'auto',
                                                    padding: '5px 10px',
                                                    backgroundColor: '#f5f3ee',
                                                    borderRadius: '5px',
                                                }}
                                                >
                                                {carddetail.mySubject[0]}
                                                <img src={rightarrow} alt="Arrow" />
                                                </span>
                                            </div>
                                            {/* Choose Button */}
                                            <div className="ms-auto d-flex flex-column">
                                            <span
                                                className="cocardbtn2 mt-2"
                                                onClick={() => {
                                                getExpertSettingById(carddetail.user_id);
                                                setExpertId(carddetail.user_id)
                                                setChooseExpertSession(false);
                                                setCalanderModal(true);
                                                }}
                                            >
                                                Choose
                                            </span>
                                            </div>
                                    </div>
                                    
                                    </div>
                                </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* calander modal started */}
            <Modal centered show={calanderModal} onHide={() => setCalanderModal(false)}>
                <ModalBody>
                   <Modal.Header className='btn btn-close ms-auto' onClick={() => setCalanderModal(false)}></Modal.Header>
                   <div>
                    <dl>
                        <dd className='cotitle2 fs-5 mt-3'>Book a session</dd>
                        <dd className='copara'>With Micheel Bennett</dd>
                        {/*  */}
                        <div style={{overflowY:'scroll', height:'350px',scrollbarWidth:'none'}}>
                        <dd style={{marginTop:'20px',background: '#F5F3EE',borderRadius:'15px',boxShadow: '0px 4px 4px 0px #00000040'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                className="custom-calendar"
                                value={selectedDate ? dayjs(selectedDate) : null}
                                onChange={(newDate) => setSelectedDate(newDate?.format('YYYY-MM-DD'))}
                                minDate={today}
                                maxDate={maxDate}
                                shouldDisableDate={(date) => {
                                    const dayOfWeek = dayjs(date).format('dddd');
                                    return !disabledDays.includes(dayOfWeek);
                                }}
                                dayOfWeekFormatter={(day) => {
                                    const dayIndex = day.day(); 
                                    console.log(dayIndex);
                                    const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
                                    return dayLabels[dayIndex];
                                }}                                
                            />
                        </LocalizationProvider>
                        </dd>
                        {/*  */}
                        <div>
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div className='cotitle2'>Choose a time</div>
                                <div className='copara d-flex' style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.8rem', cursor: 'pointer' }}>
                                        <span className='d-flex align-items-center me-4' onClick={handlePrev}><img src={leftarrow} alt="Previous" />Prev</span>
                                        <span className='d-flex align-items-center' onClick={handleNext}>Next<img src={rightarrow} alt="Next" /></span>
                                    </div>
                            </div>
                        
                            <div className="d-flex flex-wrap mb-5">
                                {currentSlots?.map((daySlot, index) => (
                                    <div key={index} className="col-3 ps-2 mb-2">
                                        <input
                                            readOnly
                                            value={daySlot.timing.split(' - ')[0]}
                                            type='text'
                                            className='mt-3 coinput'
                                            onClick={() => handleInputClick(index, daySlot.timing)}
                                            style={{
                                                backgroundColor: selectedInputIndex === index ? 'black' : '#F5F3EE',
                                                color: selectedInputIndex === index ? 'white' : '#000000'
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                         {/*  */}
                      <button className='cobooknowbutton mb-3' onClick={() => bookexpert()}>Book now</button>

                    </div>
                    </dl>
                   </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
export default CourseOutline;